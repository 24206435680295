//@ts-ignore
import hoverEffect from 'hover-effect';

document.querySelectorAll('[udesly-distortion-image]').forEach((el) => {
	const image1 = ((el.querySelector('[distortion-image="image1"]') as HTMLElement).getAttribute('src') || '')
		.startsWith('http')
		? `https://cors-anywhere.herokuapp.com/${(el.querySelector(
				'[distortion-image="image1"]'
			) as HTMLElement).getAttribute('src')}`
		: (el.querySelector('[distortion-image="image1"]') as HTMLElement).getAttribute('src');
	const image2 = ((el.querySelector('[distortion-image="image2"]') as HTMLElement).getAttribute('src') || '')
		.startsWith('http')
		? `https://cors-anywhere.herokuapp.com/${(el.querySelector(
				'[distortion-image="image2"]'
			) as HTMLElement).getAttribute('src')}`
		: (el.querySelector('[distortion-image="image2"]') as HTMLElement).getAttribute('src');

	const displacementImage = ((el.querySelector(
		'[distortion-image="displacement-image"]'
	) as HTMLElement).getAttribute('src') || '')
		.startsWith('http')
		? `https://cors-anywhere.herokuapp.com/${(el.querySelector(
				'[distortion-image="displacement-image"]'
			) as HTMLElement).getAttribute('src')}`
		: (el.querySelector('[distortion-image="displacement-image"]') as HTMLElement).getAttribute('src');

	el.innerHTML = '';

	new hoverEffect({
		parent: el,
		intensity1: 0.1,
		intensity2: 0.1,
		angle2: Math.PI / 2,
		image1: image1,
		image2: image2,
		displacementImage: displacementImage
	});
});
